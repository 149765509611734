import React, { FC } from "react";
import { Slide } from "pure-react-carousel";
import * as styles from "../OpenSourceProjectsSlider.module.scss";

type Props = {
  name: any;
  sliderIndex: number;
  about: any;
  btn: any;
};

const OpenSourceProjectsSlider: FC<Props> = ({
  name,
  sliderIndex,
  about,
  btn,
}) => {
  return (
    <div
      style={{
        width: "50%",
        justifyContent: "space-between",
        paddingRight: "2em",
        boxSizing: "border-box",
        paddingBottom: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <Slide
          index={sliderIndex}
          className={styles.subDiv}
          style={{ paddingBottom: "0px", justifyContent: "space-around" }}
        >
          <div className={styles.carouselImageDiv}>
            <h3>{name}</h3>
          </div>
        </Slide>
        <div className={styles.paragraph}>
          <p className={styles.PartnersCarouselDesc}>{about}</p>
        </div>
      </div>
      <div className={styles.btnDiv}>{btn}</div>
    </div>
  );
};

export default OpenSourceProjectsSlider;
