import React, { FC, useContext } from "react";
import * as styles from "./OpenSourceMain.module.scss";
import tableDesktop from "../../images/opensourceheader.jpg";
import tableDesktopMobile from "../../images/mobile-images/opensourceheader.webp";
import { UseWindowSize } from "../hooks/UseWindowSize";
import OpenSourceMainImg from "../../images/opensource-projects.jpg";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import CircleOutline from "../Shared/CircleOutlineAndBlur/CircleOutline";
import CircleBlur from "../Shared/CircleOutlineAndBlur/CircleBlur";
import OpenSourceProjectsMobileSlider from "../OptimizationUiComponents/Slider/OpenSourceProjectsMobileSlider";
import OpenSourceProjectsSlider from "../OptimizationUiComponents/Slider/OpenSourceProjectsSlider";
import {
  projects,
  opensource,
  technologiesEng,
  technologiesDE,
} from "./OpenSourceMain.helper";
import { CarouselProvider, Slider } from "pure-react-carousel";
import CaseStudiesSliderButtons from "../HomeUiComponents/CaseStudiesMain/Slider/CaseStudiesSliderButtons";
import HeroBanner from "../Shared/HeroBanner/HeroBanner";
import HeroBannerMobile from "../Shared/HeroBannerMobile/HeroBannerMobile";
import translate from "../../i18n/translate";
import { LanguageContext } from "../../context/languageContext";

const OpenSourceMain: FC = () => {
  const width = UseWindowSize();
  const { language } = useContext(LanguageContext);

  return (
    <div className={styles.partners}>
      {width.width < 1200 ? (
        <HeroBannerMobile
          heading={translate("opensource-heading")}
          description={translate("opensource-heading-description")}
          homeBackground={tableDesktopMobile}
          buttonText={translate("opensource-action-button")}
          date=""
          link="https://github.com/globaldatanet"
          alt="Follow us at github"
        />
      ) : (
        <HeroBanner
          heading={translate("opensource-heading")}
          description={translate("opensource-heading-description")}
          homeBackground={tableDesktop}
          buttonText={translate("opensource-action-button")}
          date=""
          link="https://github.com/globaldatanet"
        />
      )}

      <div className={styles.knowUs}>
        <div className={styles.knowUsImage}>
          <div className={styles.knowUsSubDiv}>
            <img
              src={OpenSourceMainImg}
              alt="Island with street footage"
              className={styles.awsMainImage}
            />
          </div>
        </div>
        <div className={styles.knowUsText}>
          <h2 className="heading">
            <span>.</span>
            {opensource?.title}
          </h2>
          <p>{translate("opensource-description")}</p>
          <CircleOutline
            isBlurVisible={true}
            initialScale={0.3}
            animateScale={1}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundCircleLeft}
          />
          <CircleBlur
            initialScale={0.1}
            initialOpacity={0}
            animateScale={1.8}
            animateOpacity={0.15}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundInnerCircleLeft}
          />
        </div>
      </div>

      <InnerWrapper>
        <div className={styles.partnersBenefits}>
          <div className={styles.partnersBenefitsTech}>
            <h2 className="heading">
              <span>.</span>
              {translate("opensource-projectsHeading")}
            </h2>
            <CircleOutline
              isBlurVisible={true}
              initialScale={0.3}
              animateScale={1}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundCircleLeft}
            />
            <CircleBlur
              initialScale={0.1}
              initialOpacity={0}
              animateScale={1.8}
              animateOpacity={0.3}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundInnerCircleLeft}
            />
            <div className={styles.carouselContainer}>
              {width.width <= 770 ? (
                <OpenSourceProjectsMobileSlider
                  data={language === "EN" ? technologiesEng : technologiesDE}
                />
              ) : (
                <CarouselProvider
                  className={"partners_carousel__container carousel__container"}
                  naturalSlideWidth={120}
                  naturalSlideHeight={125}
                  totalSlides={projects.length}
                  visibleSlides={
                    width.width > 1700 ? 3 : width.width > 1200 ? 3 : 2
                  }
                  currentSlide={0}
                  infinite
                >
                  <CaseStudiesSliderButtons
                    sliderButtonsClassName={styles.partnersButtonsContainer}
                  />
                  <Slider
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {projects?.map((item, index) => {
                      return (
                        <OpenSourceProjectsSlider
                          key={index}
                          sliderIndex={index}
                          name={item?.name}
                          about={item?.desc}
                          btn={item?.btn}
                        />
                      );
                    })}
                  </Slider>
                </CarouselProvider>
              )}
            </div>
          </div>
        </div>
      </InnerWrapper>
    </div>
  );
};

export default OpenSourceMain;
