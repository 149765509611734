import React from "react";
import OpenSourceMain from "../components/OpenSourceUiComponents/OpenSourceMain";
import Layout from "../components/Layout/Layout";
import { SEO } from "../components/seo";
import tableDesktop from "../images/opensourceheader.jpg";
const OpenSource = () => {
  return (
    <Layout location="/opensource">
      <SEO
        title="Open Source"
        description="We are proud to share open source projects with the community. We believe that open source is good for everyone and we are committed to bringing the value of open source to our customers."
        type="website"
        image={tableDesktop}
      />
      <OpenSourceMain />
    </Layout>
  );
};

export default OpenSource;
