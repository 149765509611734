// extracted by mini-css-extract-plugin
export var PartnerMobileCarousel = "OpenSourceProjectsSlider-module--PartnerMobileCarousel--vuG92";
export var PartnersCarouselDesc = "OpenSourceProjectsSlider-module--PartnersCarouselDesc--6kO8K";
export var blogArrowContainer = "OpenSourceProjectsSlider-module--blogArrowContainer--HRq3o";
export var btnDiv = "OpenSourceProjectsSlider-module--btnDiv--aj6tG";
export var carousel = "OpenSourceProjectsSlider-module--carousel--frIsT";
export var carouselDiv = "OpenSourceProjectsSlider-module--carouselDiv--IVX+R";
export var carouselImage = "OpenSourceProjectsSlider-module--carouselImage--P1wdW";
export var carouselImageDiv = "OpenSourceProjectsSlider-module--carouselImageDiv--nYcxg";
export var carouselImg = "OpenSourceProjectsSlider-module--carouselImg--5KL5J";
export var carouselImgDiv = "OpenSourceProjectsSlider-module--carouselImgDiv--TV7af";
export var carouselMainDiv = "OpenSourceProjectsSlider-module--carouselMainDiv--cBhhN";
export var carousel__container = "OpenSourceProjectsSlider-module--carousel__container--7wa8Q";
export var carousel__slider = "OpenSourceProjectsSlider-module--carousel__slider--Aj3re";
export var carousel__sliderTrayWrapHorizontal = "OpenSourceProjectsSlider-module--carousel__slider-tray-wrap--horizontal--4yWNb";
export var carousel__sliderTrayWrapper = "OpenSourceProjectsSlider-module--carousel__slider-tray-wrapper--yId4H";
export var caseStudiesDescription = "OpenSourceProjectsSlider-module--caseStudiesDescription--vPVGf";
export var contactButton = "OpenSourceProjectsSlider-module--contactButton--qs3y2";
export var paragraph = "OpenSourceProjectsSlider-module--paragraph--S-Q+q";
export var partnersMainSlider = "OpenSourceProjectsSlider-module--partnersMainSlider--VCJlv";
export var subDiv = "OpenSourceProjectsSlider-module--subDiv--VISjg";