import React from "react";
import CustomButton from "../Shared/CustomButton/CustomButton";
import arrowRightWhite from "../../images/arrowRightWhite.svg";
import translate from "../../i18n/translate";

export const projects = [
  {
    name: translate("opensource-projectheader1"),
    desc: translate("opensource-projectdesc1"),
    btn: (
      <CustomButton
        text={translate("opensource-project-action-button")}
        image={arrowRightWhite}
        externalLink={"https://github.com/globaldatanet/aws-firewall-factory"}
      />
    ),
  },
  {
    name: translate("opensource-projectheader2"),
    desc: translate("opensource-projectdesc2"),
    btn: (
      <CustomButton
        text={translate("opensource-project-action-button")}
        image={arrowRightWhite}
        externalLink={"https://github.com/globaldatanet/cost-reporter"}
      />
    ),
  },
  {
    name: translate("opensource-projectheader3"),
    desc: translate("opensource-projectdesc3"),
    btn: (
      <CustomButton
        text={translate("opensource-project-action-button")}
        image={arrowRightWhite}
        externalLink={"https://github.com/globaldatanet/ecs-shutdown-scheduler"}
      />
    ),
  },
  {
    name: translate("opensource-projectheader6"),
    desc: translate("opensource-projectdesc6"),
    btn: (
      <CustomButton
        text={translate("opensource-project-action-button")}
        image={arrowRightWhite}
        externalLink={"https://github.com/daknhh/aws-orgtool"}
      />
    ),
  },
  {
    name: translate("opensource-projectheader4"),
    desc: translate("opensource-projectdesc4"),
    btn: (
      <CustomButton
        text={translate("opensource-project-action-button")}
        image={arrowRightWhite}
        externalLink={
          "https://github.com/globaldatanet/-WAF-Managed-Rules-Update-Slack-Notification-Service"
        }
      />
    ),
  },
  {
    name: translate("opensource-projectheader5"),
    desc: translate("opensource-projectdesc5"),
    btn: (
      <CustomButton
        text={translate("opensource-project-action-button")}
        image={arrowRightWhite}
        externalLink={"https://github.com/globaldatanet/marketplace-automation"}
      />
    ),
  },
];

export const technologiesDE = [
  {
    name: "AWS Firewall Factory",
    desc: "In einfachen Szenarien und für kleinere Anwendungen ist es sehr einfach, WAFs auf individueller Basis zu implementieren. In größeren Umgebungen mit Dutzenden oder sogar Hunderten von Anwendungen ist es jedoch ratsam, eine zentrale Verwaltung und Automatisierung anzustreben. Mit dieser einfachen Lösung können Sie Ihre Web Application Firewalls bereitstellen, aktualisieren und bereitstellen, während Sie sie zentral über AWS Firewall Manager verwalten.",
    btn: (
      <CustomButton
        text={"Explore this project"}
        image={arrowRightWhite}
        externalLink={"https://github.com/globaldatanet/aws-firewall-factory"}
      />
    ),
  },
  {
    name: "Cost Reporter",
    desc: "Ein Lambda, die einen täglichen Kosten- und Trendbericht an Slack sendet. Es hilft bei einer der drei FinOps-Phasen: Informieren. Es kann Ihnen jeden Tag einen Bericht senden / nur wenn die Kosten steigen / nur wenn die Kosten einen Schwellenwert überschreiten, je nach Ihrer Konfiguration. Dies ist hilfreich, um neue Projekte im Auge zu behalten, wenn sich die Architektur schnell ändert, aber auch für bestehende Projekte, wenn Sie die Kosten im Auge behalten wollen.",
    btn: (
      <CustomButton
        text={"Explore this project"}
        image={arrowRightWhite}
        externalLink={"https://github.com/globaldatanet/cost-reporter"}
      />
    ),
  },
  {
    name: "ECS Scheduler",
    desc: "Herunterfahren und Starten von Containern zur Kosteneinsparung in Entwicklungsumgebungen. ECS-Dienste mit oder ohne konfigurierte Autoskalierung können mit diesem Lambda heruntergefahren werden. Standardmäßig werden sie um 7:00 Uhr morgens gestartet und um 21:00 Uhr abends gestoppt, was zu einer Kosteneinsparung von ca. 40 % für ECS in Entwicklungsumgebungen führen sollte.",
    btn: (
      <CustomButton
        text={"Explore this project"}
        image={arrowRightWhite}
        externalLink={"https://github.com/globaldatanet/ecs-shutdown-scheduler"}
      />
    ),
  },
  {
    name: "WAF Notification",
    desc: "AWS WAF bietet jetzt frühzeitige Benachrichtigungen über bevorstehende Regelaktualisierungen für Ihre verwalteten Regelgruppen über Amazon Simple Notification Service (Amazon SNS). Diese Automatisierung sendet Slack-Benachrichtigungen über verwaltete Regelaktualisierungen für eine bestimmte verwaltete Regelgruppe.",
    btn: (
      <CustomButton
        text={"Explore this project"}
        image={arrowRightWhite}
        externalLink={
          "https://github.com/globaldatanet/WAF-Managed-Rules-Update-Slack-Notification-Service"
        }
      />
    ),
  },
  {
    name: "AWS Marketplace Automation",
    desc: "Die Lösung kümmert sich um neu hinzugefügte Konten, bestehende Konten, die SUSPENDED sind oder wenn das spezifische Tag für Lizenzen in einem Konto aktualisiert wird - dass jede bestehende Lizenz geteilt oder von diesem Konto entfernt wird.",
    btn: (
      <CustomButton
        text={"Explore this project"}
        image={arrowRightWhite}
        externalLink={"https://github.com/globaldatanet/marketplace-automation"}
      />
    ),
  },
  {
    name: "AWS ORGTOOL",
    desc: "Mit diesem Tool können Sie die Struktur Ihrer AWS-Organisationen und Policies in eine Json-Datei exportieren und die Struktur und SCPs in eine andere AWS-Organisation importieren. Während des Imports und Exports wird eine Protokolldatei geschrieben, so dass die Prozesse nachverfolgt werden können.",
    btn: (
      <CustomButton
        text={"Explore this project"}
        image={arrowRightWhite}
        externalLink={"https://github.com/daknhh/aws-orgtool"}
      />
    ),
  },
];

export const technologiesEng = [
  {
    name: "AWS Firewall Factory",
    desc: "In simple scenarios and for smaller applications, its is very easy to implement WAFs on an individual basis. However, in larger environments with tens or even hundreds of applications, it is advisable to aim for central governance and automation. This simple solution helps you deploy, update, and stage your Web Application Firewalls while managing them centrally via AWS Firewall Manager.",
    btn: (
      <CustomButton
        text={"Explore this project"}
        image={arrowRightWhite}
        externalLink={"https://github.com/globaldatanet/aws-firewall-factory"}
      />
    ),
  },
  {
    name: "Cost Reporter",
    desc: "A lambda which sends a daily cost and trend report to slack. It helps with one of the three FinOps phases: Inform. It can send you a report every day / only when cost increases / only when cost breached a threshold depending on your configuration. ",
    btn: (
      <CustomButton
        text={"Explore this project"}
        image={arrowRightWhite}
        externalLink={"https://github.com/globaldatanet/cost-reporter"}
      />
    ),
  },
  {
    name: "ECS Scheduler",
    desc: "Shuts down and starts containers for cost saving in development environments. ECS Services with or without autoscaling configured can be shutdown using this lambda. Per default they are started at 7:00 AM and stopped at 9:00 PM, which should result in ~40% of cost saving for ECS in dev.",
    btn: (
      <CustomButton
        text={"Explore this project"}
        image={arrowRightWhite}
        externalLink={"https://github.com/globaldatanet/ecs-shutdown-scheduler"}
      />
    ),
  },
  {
    name: "WAF Notification",
    desc: "AWS WAF now provides early notifications of upcoming rule updates to your managed rule groups through Amazon Simple Notification Service (Amazon SNS). This automation will send slack notifactions of managed rule updates for a specific managed rule group.",
    btn: (
      <CustomButton
        text={"Explore this project"}
        image={arrowRightWhite}
        externalLink={
          "https://github.com/globaldatanet/WAF-Managed-Rules-Update-Slack-Notification-Service"
        }
      />
    ),
  },
  {
    name: "AWS Marketplace Automation",
    desc: "The solution will take care of new accounts added, existing ones which are SUSPENDED or when the specific tag for licences is updated in one account - that every existing licence will be shared or removed from that account.",
    btn: (
      <CustomButton
        text={"Explore this project"}
        image={arrowRightWhite}
        externalLink={"https://github.com/globaldatanet/marketplace-automation"}
      />
    ),
  },
  {
    name: "AWS ORGTOOL",
    desc: "This Tool is to export your AWS Organizations structure, Policies to Json file and import structure, SCPs to another AWS Organization. During import and export a a log file is written so that the processes can be traced.",
    btn: (
      <CustomButton
        text={"Explore this project"}
        image={arrowRightWhite}
        externalLink={"https://github.com/daknhh/aws-orgtool"}
      />
    ),
  },
];

export const opensource = {
  title: translate("opensource-description-header"),
  description: translate("opensource-description"),
};
