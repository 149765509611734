// extracted by mini-css-extract-plugin
export var awsExpertImg = "OpenSourceMain-module--awsExpertImg--LXtsv";
export var awsExpertImgDiv = "OpenSourceMain-module--awsExpertImgDiv--F8y4L";
export var awsMainImage = "OpenSourceMain-module--awsMainImage--LQf-J";
export var businessImage = "OpenSourceMain-module--businessImage--h8yIK";
export var businessItem = "OpenSourceMain-module--businessItem--x6Bc+";
export var businessItemImage = "OpenSourceMain-module--businessItemImage--XN6cM";
export var businessItemMain = "OpenSourceMain-module--businessItemMain--rdZYx";
export var businessMain = "OpenSourceMain-module--businessMain--a9sm8";
export var businessText = "OpenSourceMain-module--businessText--RTIrZ";
export var buttonClasses = "OpenSourceMain-module--buttonClasses--IFHLs";
export var carouselContainer = "OpenSourceMain-module--carouselContainer--caBnN";
export var contactButton = "OpenSourceMain-module--contactButton--suQJx";
export var heroBackgroundCircleLeft = "OpenSourceMain-module--heroBackgroundCircleLeft--KQC6b";
export var heroBackgroundInnerCircleLeft = "OpenSourceMain-module--heroBackgroundInnerCircleLeft--PvUNK";
export var knowUs = "OpenSourceMain-module--knowUs--yYyJx";
export var knowUsImage = "OpenSourceMain-module--knowUsImage--3bqKW";
export var knowUsSubDiv = "OpenSourceMain-module--knowUsSubDiv--spmm0";
export var knowUsText = "OpenSourceMain-module--knowUsText--P8oh1";
export var partners = "OpenSourceMain-module--partners--uE-Cd";
export var partnersBenefits = "OpenSourceMain-module--partnersBenefits--ZtSPY";
export var partnersBenefitsCarousel = "OpenSourceMain-module--partnersBenefitsCarousel--cAT6I";
export var partnersBenefitsInner = "OpenSourceMain-module--partnersBenefitsInner--xP+0W";
export var partnersBenefitsItem = "OpenSourceMain-module--partnersBenefitsItem--wym4P";
export var partnersBenefitsItemBusiness = "OpenSourceMain-module--partnersBenefitsItemBusiness--A-IFH";
export var partnersBenefitsItemBusinessTop = "OpenSourceMain-module--partnersBenefitsItemBusinessTop--qCUXl";
export var partnersBenefitsTech = "OpenSourceMain-module--partnersBenefitsTech--FiuzI";
export var partnersButtonsContainer = "OpenSourceMain-module--partnersButtonsContainer--983P1";
export var partnersMainSlider = "OpenSourceMain-module--partnersMainSlider---yJfR";